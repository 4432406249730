import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../assets/css/style.css";
import "../assets/js/main";
import TextTruncate from "react-text-truncate";
import { Link } from "react-router-dom";
import elderly001 from "../assets/images/svg/svg/001-elderly.svg";
import elderly002 from "../assets/images/svg/svg/002-elderly-1.svg";
import elderly003 from "../assets/images/svg/svg/003-rocking-chair.svg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { HashLink } from 'react-router-hash-link';

function Features() {
  const location = useLocation()
  
  useEffect(()=> {
    if (location.hash){
      let elem = document.getElementById(location.hash.slice(1))
      if (elem){
        elem.scrollIntoView({behavior: "smooth"})
      }
    }else {
      window.scrollTo({top:0, left:0, behavior:"smooth"})
    }
  }, [location,])

  return (
    <section id="services">
      
      <div className="feature-v1">
        <div className="d-lg-flex align-items-center w-100">
          <div className="d-flex pagination-item  h-100">
            <span className="icon-wrap">
              <img src={elderly001} alt="elderly care" className="img-fluid" />
            </span>
            <div>
              <span className="subheading">
                <h3>Our Mission</h3>
              </span>
              <span className="lead text-black">
                <TextTruncate
                  line={3}
                  element="span"
                  truncateText="…"
                  text="Our mission is to improve our clients lives and health by providing professional services, enabling them to achieve their full potential of every day"
                />
              </span><br/>
              <HashLink
                to="/about#mission"
                className="small link-offset-2 link-underline link-underline-opacity-10 link-info"
                // onClick={() => {
                //   window.scroll({ top: 0, left: 0, behavior: "smooth" });
                // }}
              >
                Learn More
              </HashLink>
            </div>
          </div>
          <div className="d-flex pagination-item h-100">
            <span className="icon-wrap">
              <img src={elderly002} alt="elderly care" className="img-fluid" />
            </span>
            <div>
              <span className="subheading">
                <h3>Our Caregivers</h3>
              </span>
              <span className="lead text-black">
                <TextTruncate
                  line={3}
                  element="span"
                  truncateText="…"
                  text="We ensure our caregivers are highly skilled and compassionate while giving attention to our clients. With them, you can be sure your loved ones are always safe and have the highest level of care. Since we maintain such a high level of care, we offer regular training for our employees so they can stay in touch with the latest trends in this field of work."
                />
              </span><br/>
              <HashLink
                to="/about#caregivers"
                className="small link-offset-2 link-underline link-underline-opacity-10 link-info"
                // onClick={() => {
                //   window.scroll({ top: 0, left: 0, behavior: "smooth" });
                // }}
              >
                Learn More
              </HashLink>
            </div>
          </div>
          <div className="d-flex pagination-item h-100">
            <span className="icon-wrap">
              <img src={elderly003} alt="elderly care" className="img-fluid" />
            </span>
            <div>
              <span className="subheading">
                <h3>Our Goal</h3>
              </span>
              <span className="lead text-black">
                <TextTruncate
                  line={3}
                  element="span"
                  truncateText="…"
                  text="The main goal of My Passion for Seniors is to bring smiles to the faces of our patients and their families. Our home care services target a wide range of elderly people. We seek to make a significant difference in your life by being there for your family during the times you need us most."
                />
              </span><br/>
              <HashLink
                to="/about#goal"
                className="small link-offset-2 link-underline link-underline-opacity-10 link-info"
                // onClick={() => {
                //   window.scroll({ top: 0, left: 0, behavior: "smooth" });
                // }}
              >
                Learn More
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Services() {
  return (
    <section id="services">
      
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-7 text-center">
              <div className="heading">
                <h2 className="text-black">Our Services</h2>
              </div>
              <p className="lead text-dark">
                Our services include...
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 mb-4 mb-lg-0 col-lg-3">
              <div className="service">
                <HashLink
                  to="/about#companionship"
                  className="d-block"
                  // onClick={() => {
                  //   window.scroll({ top: 0, left: 0, behavior: "smooth" });
                  // }}
                >
                  <img
                    src={require("../assets/images/hero-1.jpg")}
                    alt="companionship care"
                    className="img-fluid"
                  />
                </HashLink>
                <div className="service-inner">
                  <h3>Companionship Care</h3>
                  <p style={{ fontSize: "20px" }} className="lead text-black">
                    <TextTruncate
                      line={4}
                      element="span"
                      truncateText="…"
                      text="We offer this special service which include:
                  Pick up mail
                  Sorting and organizing
                  Transportation to doctor’s appointments and outing
                  Meal preparation and serving
                  Light housekeeping
                  Laundry and ironing services
                  Shopping and errand services
                  Medication reminders
                  Correspondence services
                  Walking companion
                  Packing and unpacking."
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mb-4 mb-lg-0 col-lg-3">
              <div className="service">
                <HashLink
                  to="/about#homemaking"
                  className="d-block"
                  // onClick={() => {
                  //   window.scroll({ top: 0, left: 0, behavior: "smooth" });
                  // }}
                >
                  <img
                    src={require("../assets/images/homecare-1.jpg")}
                    alt="home care"
                    className="img-fluid"
                  />
                </HashLink>
                <div className="service-inner">
                  <h3>Homemaking Care</h3>
                  <p style={{ fontSize: "20px" }} className="lead text-black">
                    <TextTruncate
                      line={4}
                      element="span"
                      truncateText="…"
                      text="We offer household services in order to keep your home a clean and safe place. Our services are available 24 hours a day, seven days a week. Homemaking services are a great option for when you first begin using assistance in the comfort of your home. It includes:
                  Meal planning and preparation
                  Light housekeeping
                  Vacuuming and dusting
                  Changing bedsheets
                  Laundry
                  Dishwashing
                  Assistance soring and reading the mail etc."
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mb-4 mb-lg-0 col-lg-3">
              <div className="service">
                <HashLink
                  to="/about#personal"
                  className="d-block"
                  // onClick={() => {
                  //   window.scroll({ top: 0, left: 0, behavior: "smooth" });
                  // }}
                >
                  <img
                    src={require("../assets/images/personalcare.jpg")}
                    alt="personal care"
                    className="img-fluid"
                  />
                </HashLink>
                <div className="service-inner">
                  <h3>Personal Care</h3>
                  <p style={{ fontSize: "20px" }} className="lead text-black">
                    <TextTruncate
                      line={4}
                      element="span"
                      truncateText="…"
                      text="Personal Care is one of the main services in the Home Care industry. It includes assistance with everyday activities to help the ones in need and make the elderly life peaceful. Personal care services are related to assistance with dressing, hygiene, and incontinence services. It includes the following activities:
                  Bathing assistance
                  Incontinence Care (toileting and grooming, and dressing)
                  Blood pressure and weight checks
                  ADL’s (Activity of Daily Living)
                  Feeding."
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mb-4 mb-lg-0 col-lg-3">
              <div className="service">
                <HashLink
                  to="/about#privateduty"
                  className="d-block"
                  // onClick={() => {
                  //   window.scroll({ top: 0, left: 0, behavior: "smooth" });
                  // }}
                >
                  <img
                    src={require("../assets/images/24_7_sq.jpg")}
                    alt="24/7 Private Duty"
                    className="img-fluid"
                  />
                </HashLink>
                <div className="service-inner">
                  <h3>24/7 Private Duty</h3>
                  <p style={{ fontSize: "20px" }} className="lead text-black">
                    <TextTruncate
                      line={4}
                      element="span"
                      truncateText="…"
                      text="Professional and trained home caregivers take care of your loved ones in a way that ensures meeting the requirements of their individual needs. And caregiver or sitting caregiver for CAN or Companion (hours are 7am – 7pm, 7pm – 7am, 8am – 8pm and 8pm – 8am or 7am – 3pm, 3pm – 11pm, 11pm – 7am)."
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </section>
  );
}

export { Features, Services };
